<script setup>
import { ref, toRef, watch, defineProps } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { GET_USER_WORKSPACES_SHORT } from '@/graphql/queries'
import { useRoute, useRouter } from 'vue-router'
const props = defineProps({
  userId: {
    type: String
  }
})
const router = useRouter()
const route = useRoute()
const userId = toRef(props, 'userId')
const workspaces = ref([])
const roles = ref({})
const { onResult: onWorkspaceResult, refetch } = useQuery(GET_USER_WORKSPACES_SHORT, { userId: userId }, { fetchPolicy: 'no-cache' })

onWorkspaceResult(({ data }) => {
  workspaces.value = data.listWorkspaceMemberRolesByUserId.map((w) => w.workspace)
  roles.value = {}
   data.listWorkspaceMemberRolesByUserId.forEach((w) => {
    roles.value[w.workspace.id] = w.role
   })
  if (!route.matched.find((r) => r.name === 'workspace')) {
    return router.push({ name: 'workspace', params: { workspaceId: data.listWorkspaceMemberRolesByUserId[0]?.workspace.id }, replace: true })
  }
})

watch(route, () => {
  if (route.name === 'user') {
    refetch()
  }
})

</script>

<template>
  <router-view
    v-if="workspaces.length"
    :workspaces="workspaces"
    :roles="roles"
  />
</template>

<style scoped>

</style>

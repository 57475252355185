<template>
  <a-modal
    v-model:open="showPlaylistModal"
    :title="selectedPlaylist?.name"
    :footer="null"
    width="1000px"
  >
    <a-row :gutter="[0, 16]">
      <a-col :span="8">
        <JsonViewer
          :value="selectedPlaylist"
          copyable
          expanded
          sort
          theme="jv-light"
        />
      </a-col>
      <a-col :span="16">
        <VersionsTable :playlist-id="playlistId" />
      </a-col>
    </a-row>
  </a-modal>
  <a-table
    :data-source="playlistsList"
    :columns="playlistsColumns"
    :loading="playlistLoading"
    size="small"
    :pagination="playlistsPaginationConfig"
    :custom-row="customRow"
    style="margin-top: 8px; overflow-x: auto"
    :scroll="{ x: 600 }"
    class="table-padded"
    @resize-column="handleResizeColumn"
    @change="onPlaylistsPageChange"
  >
    <template #bodyCell="{ column, text }">
      <template v-if="['createdAt','updatedAt'].includes(column.key)">
        {{ formatDate(text) }}
      </template>

      <template v-else>
        {{ text }}
      </template>
    </template>
  </a-table>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref, toRef } from 'vue'
import { LIST_PLAYLISTS } from '@/graphql/queries'
import { useQuery, useResult } from '@vue/apollo-composable'
import { DEFAULT_PAGINATION } from '@/constants'
import { cloneDeep } from 'lodash'
import { formatDate } from '@/utils'
import VersionsTable from '@/components/VersionsTable'

const PLAYLISTS_COLUMNS = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  resizable: true,
  ellipsis: true,
  width: 200,
  maxWidth: 400,
  minWidth: 50,
  fixed: 'left'
}, {
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Updated At',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  width: 150,
  minWidth: 150
}]

export default defineComponent({
  name: 'PlaylistsView',
  components: { VersionsTable },
  props: {
    groupId: {
      type: String,
      required: true
    },
    userId: {
      type: String
    },
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const groupId = toRef(props, 'groupId')
    const playlistId = ref(null)
    const playlistsQueryEnabled = ref(!!groupId.value)
    const playlistsSettings = ref({ enabled: playlistsQueryEnabled })

    const playlistsQueryOptions = ref({ filters: { groupId }, pagination: { ...DEFAULT_PAGINATION } })
    const { result: playlistsResult, loading: playlistLoading } = useQuery(LIST_PLAYLISTS, playlistsQueryOptions, playlistsSettings)
    const playlistsList = useResult(playlistsResult, [], data => data.listPlaylists.data)
    const playlistsPagination = useResult(playlistsResult, [], data => data.listPlaylists.pagination)

    const showPlaylistModal = ref(false)
    const selectedPlaylist = ref(null)
    const playlistsPaginationConfig = computed(() => {
      return {
        total: playlistsPagination.value?.total,
        pageSize: DEFAULT_PAGINATION.limit
      }
    })

    const playlistsColumns = ref(cloneDeep(PLAYLISTS_COLUMNS))

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const onPlaylistsPageChange = async (pagination) => {
      playlistsQueryOptions.value.pagination.offset = (pagination.current - 1) * DEFAULT_PAGINATION.limit + 1
    }

    const customRow = (record) => {
      return {
        onClick: () => {
          selectedPlaylist.value = record
          playlistId.value = record.id
          showPlaylistModal.value = true
        }
      }
    }

    onMounted(() => {
      playlistsSettings.value.enabled = false
      nextTick(() => {
        playlistsSettings.value.enabled = true
      })
    })

    return {
      playlistsColumns,
      playlistLoading,
      playlistsList,
      selectedPlaylist,
      playlistsPaginationConfig,
      showPlaylistModal,
      playlistId,
      customRow,
      formatDate,
      onPlaylistsPageChange,
      handleResizeColumn
    }
  }
})
</script>

<style scoped>

</style>

<template>
  <a-layout
    v-if="user"
    style="min-height: 100%;"
  >
    <a-layout-content style="padding: 24px; width: 1200px; margin: auto;">
      <a-form-item style="margin-bottom: 8px;">
        <a-switch
          :checked="state2Fa"
          @click="handle2FaStateChange"
        />
        <a-typography-text style="margin-left: 16px;">
          Two-Factor Authentication
        </a-typography-text>
      </a-form-item>
      <div v-if="state2Fa">
        <template v-if="!is2faSet && qrCodeUrl">
          <a-row style="margin-top: 16px;margin-bottom: 16px;">
            <a-col :span="18">
              <a-typography-text>
                You have enabled Two-Factor Authentication for your account. Follow the instructions to complete setup.
              </a-typography-text>
            </a-col>
          </a-row>
          <a-row :gutter="32">
            <a-col :span="6">
              <img
                :src="qrCodeUrl"
                alt="QR Code"
                style="max-width: 100%"
              >
            </a-col>
            <a-col span="18">
              <a-typography-paragraph style="margin-bottom: 4px;">
                1. Download and install
                <a-typography-link
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                >
                  Google Authenticator
                </a-typography-link>
              </a-typography-paragraph>
              <a-typography-paragraph style="margin-bottom: 4px;">
                2. Scan this QR Code
              </a-typography-paragraph>
              <a-typography-paragraph style="margin-bottom: 4px;">
                3. Enter Verification Code
              </a-typography-paragraph>
              <a-input-search
                v-model:value="otpCode"
                placeholder="Code"
                size="large"
                :loading="otpCodeProcessing"
                style="width: 200px; margin-top: 16px;"
                maxlength="6"
                @search="handleOtpCodeEnter"
              >
                <template #enterButton>
                  <a-button>Verify</a-button>
                </template>
              </a-input-search>
            </a-col>
          </a-row>
        </template>
        <template v-else-if="is2faSet">
          <a-typography-text>
            Two-Factor Authentication is On
          </a-typography-text>
        </template>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent, createVNode, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import qrcode from 'qrcode'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { error } from '@/utils'
import { useMutation } from '@vue/apollo-composable'
import { DISABLE_2FA, FINALIZE_2FA_SETUP, PREPARE_2FA_SETUP } from '@/graphql/mutations'

function generateQRCode (otpauth) {
  return new Promise((resolve, reject) => {
    qrcode.toDataURL(otpauth, (err, imageUrl) => {
      if (err) {
        console.log('Error with QR:', err)
        reject(err)
      } else {
        resolve(imageUrl)
      }
    })
  })
}

export default defineComponent({
  name: 'SettingsPage',
  setup () {
    const store = useStore()

    const { mutate: prepare2Fa } = useMutation(PREPARE_2FA_SETUP)
    const { mutate: disable2Fa } = useMutation(DISABLE_2FA)
    const { mutate: finalize2faSetup, loading: otpCodeProcessing } = useMutation(FINALIZE_2FA_SETUP)
    const user = computed(() => store.getters['auth/user'])
    const is2faSet = computed(() => store.getters['auth/is2faSet'])
    const state2Fa = ref(is2faSet.value)
    const qrCodeUrl = ref('')
    const otpCode = ref('')

    const handle2FaStateChange = (value) => {
      if (is2faSet.value && !value) {
        return Modal.confirm({
          title: 'This will turn off Two-Factor Authentication',
          icon: createVNode(ExclamationCircleOutlined),
          content: "You will no longer receive a verification code if we notice an attempt to login from a device or browser that we don't recognize.",
          okText: 'Disable',
          cancelText: 'Cancel',
          onOk () {
            disable2FA()
          }
        })
      } else if (!is2faSet.value && value) {
        prepare2Fa().then(async ({ data: { prepare2faSetup } }) => {
          const { otpUrl } = prepare2faSetup
          const imageUrl = await generateQRCode(otpUrl)
          state2Fa.value = true
          qrCodeUrl.value = imageUrl
        })
      } else if (!is2faSet.value && !value) {
        state2Fa.value = false
      }
    }

    const disable2FA = () => {
      disable2Fa().then(() => {
        store.commit('auth/UPDATE_USER', { is2faSet: false })
        state2Fa.value = false
        qrCodeUrl.value = ''
        otpCode.value = ''
      })
    }

    const handleOtpCodeEnter = (otpCode) => {
      if (otpCode?.length === 6 && /^\d+$/.test(otpCode)) {
        finalize2faSetup({ twoFactorCode: otpCode }).then(() => {
          qrCodeUrl.value = ''
          store.commit('auth/UPDATE_USER', { is2faSet: true })
        }).catch(e => {
          error(e.message)
        }).finally(() => {
        })
      }
    }

    watch(() => is2faSet.value, (value) => {
      state2Fa.value = value
    })

    return {
      user,
      is2faSet,
      state2Fa,
      qrCodeUrl,
      otpCode,
      otpCodeProcessing,
      handle2FaStateChange,
      handleOtpCodeEnter
    }
  }
})
</script>

<style scoped>

</style>

<script setup>
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import {
  GET_USER_BY_ID,
  GET_WORKSPACE_BY_ID,
  GET_WORKSPACE_DEVICES_COUNT,
  GET_WORKSPACE_MEMBERS
} from '@/graphql/queries'
import { ref, defineProps, watch, computed } from 'vue'
import FeaturesList from '@/components/FeaturesList'
import AccountInfo from '@/components/AccountInfo'
import WorkspaceTab from '@/components/WorkspaceTab.vue'
import SubscriptionTab from '@/components/SubscriptionTab.vue'
import WorkspacePageHeader from '@/components/WorkspacePageHeader.vue'
import { SaveOutlined } from '@ant-design/icons-vue'
import { UPDATE_WORKSPACE_BY_ID } from '@/graphql/mutations'
import { error, success } from '@/utils'
import MembersTab from '@/components/MembersTab.vue'
import moment from 'moment'
import DevicesTab from '@/components/DevicesTab.vue'

const props = defineProps({
  userId: String,
  workspaceId: String,
  groupId: String,
  workspaces: Array,
  roles: Object
})

const { result: userResult, refetch: refetchUser } = useQuery(GET_USER_BY_ID, { id: props.userId })
const { result: workspaceResult, refetch: refetchWorkspace, loading: workspaceLoading } = useQuery(GET_WORKSPACE_BY_ID, { id: props.workspaceId }, { fetchPolicy: 'no-cache' })
const { result: devicesCountResult, refetch: refetchDevicesCount, loading: devicesCountLoading } = useQuery(GET_WORKSPACE_DEVICES_COUNT, { workspaceId: props.workspaceId }, { fetchPolicy: 'no-cache' })
const { result: membersResult, refetch: refetchMembers } = useQuery(GET_WORKSPACE_MEMBERS, { workspaceId: props.workspaceId }, { fetchPolicy: 'no-cache' })
const { mutate: updateWorkspace, updatingWorkspace } = useMutation(UPDATE_WORKSPACE_BY_ID)
const user = useResult(userResult, null)
const workspace = useResult(workspaceResult, null)
const devicesCount = useResult(devicesCountResult, null)
const members = useResult(membersResult, null)
const workspaceOwner = computed(() => members.value?.find(m => m.role === 'OWNER'))
const featuresDrawerVisible = ref(false)
const activeTabKey = ref('workspace')
const devices = computed(() => workspace.value?.billingInfo?.billingDevicesQuantity || 0)
const workspaceRole = computed(() => props.roles[props.workspaceId])
const isOwner = computed(() => workspaceRole.value === 'OWNER')
const freeDevices = computed(() => workspace.value?.billingInfo?.freeDevicesQuantity || 0)
const freeDevicesQuantity = ref(freeDevices.value)

const freeDevicesCoupon = computed(() => workspace.value?.activeCoupons?.find(coupon => coupon.couponType === 'ADD_FREE_DEVICES'))
const freeCouponDevicesNumber = computed(()=> freeDevicesCoupon.value?.freeDevicesCount)
const deactivationDate = computed(()=> moment(freeDevicesCoupon.value?.deactivationAt).format('MMM D YYYY'))
const deactivationDateToday = computed(()=> moment(freeDevicesCoupon.value?.deactivationAt).isSame(moment(), 'day'))


const tier = computed(() => workspace.value?.billingInfo?.tier)
const isEDUStarterTier = computed(() => tier.value === 'education-starter')
const status = computed(() => isEDUStarterTier.value ? 'free' : workspace.value?.billingInfo?.status)
const tabList = [
  {
    key: 'workspace',
    tab: 'Workspace'
  },
  {
    key: 'subscription',
    tab: 'Subscription'
  },
  {
    key: 'members',
    tab: 'Members'
  },
  {
    key: 'devices',
    tab: 'Devices'
  }
]

const openFeaturesDrawer = () => {
  featuresDrawerVisible.value = true
}

const onTabChange = (key, type) => {
  activeTabKey.value = key
}

const onUserUpdate = () => {
  refetchUser()
}

const onFeaturesUpdate = () => {
  refetchWorkspace()
}

const onSubscriptionUpdate = () => {
  refetchWorkspace()
}

const onMembersUpdate = () => {
  refetchMembers()
}

const changeFreeDevicesNumber = () => {
  updateWorkspace({
    id: props.workspaceId,
    input: {
      billingInfo: {
        freeDevicesQuantity: freeDevicesQuantity.value
      }
    }
  }).then(() => {
    refetchWorkspace()
    success()
  }).catch(e => {
    freeDevicesQuantity.value = freeDevices.value
    error(e.message)
  })
}

const onWorkspaceUpdate = () => {
  refetchWorkspace()
}

watch(() => props.workspaceId, (newVal) => {
  refetchWorkspace({id: newVal})
  refetchDevicesCount({workspaceId: newVal})
  refetchMembers({workspaceId: newVal})
})

watch(()=> freeDevices.value, (newVal) => {
  freeDevicesQuantity.value = newVal
})

</script>

<template>
  <a-layout
    v-if="user"
    style="height: 100%; background-color: #ccc;"
  >
    <a-layout-sider
      theme="light"
      width="350px"
      style="padding: 16px"
    >
      <AccountInfo
        v-if="user"
        :user="user"
        :user-id="userId"
        :owner="workspaceOwner"
        :is-owner="isOwner"
        @update="onUserUpdate"
      />
    </a-layout-sider>
    <a-layout-content style="padding: 16px;">
      <a-drawer
        v-model:open="featuresDrawerVisible"
        title="Features"
        placement="right"
      >
        <FeaturesList
          :features="workspace?.features"
          :workspace-id="workspaceId"
          @update="onFeaturesUpdate"
        />
      </a-drawer>
      <a-spin :spinning="workspaceLoading">
        <div style="display: flex; flex-direction: column; gap: 16px; height: 100%;">
          <WorkspacePageHeader
            :workspaces="workspaces"
            :workspace="workspace"
            :workspace-id="workspaceId"
            :workspace-role="workspaceRole"
            :user-id="userId"
            @update-workspace="onWorkspaceUpdate"
            @open-features-drawer="openFeaturesDrawer"
          />
          <a-row :gutter="16">
            <a-col
              class="gutter-row"
              :span="6"
            >
              <a-card class="gutter-box">
                <a-statistic :value="tier">
                  <template #title>
                    Tier
                  </template>
                </a-statistic>
              </a-card>
            </a-col>
            <a-col
              class="gutter-row"
              :span="3"
            >
              <a-card class="gutter-box">
                <a-statistic :value="status">
                  <template #title>
                    Status
                  </template>
                </a-statistic>
              </a-card>
            </a-col>
            <a-col
              class="gutter-row"
              :span="5"
            >
              <a-card class="gutter-box">
                <a-statistic :value="devicesCount || 0">
                  <template #title>
                    Devices
                  </template>
                  <template #suffix>
                    / {{ devices }}
                    <span v-if="freeDevices">(+{{ freeDevices }})</span>
                  </template>
                </a-statistic>
              </a-card>
            </a-col>
            <a-col
              class="gutter-row"
              :span="5"
            >
              <a-card class="gutter-box">
                <div class="ant-statistic css-dev-only-do-not-override-17yhhjv">
                  <div class="ant-statistic-title">
                    Free Devices <span v-if="freeCouponDevicesNumber">(+{{ freeCouponDevicesNumber }} coupons)</span>
                  </div>
                  <div class="ant-statistic-content">
                    <span class="ant-statistic-content-value">
                      <span class="ant-statistic-content-value-int">
                        <a-form @submit="changeFreeDevicesNumber">
                          <a-input-group compact>
                            <a-input-number
                              v-model:value="freeDevicesQuantity"
                              :disabled="updatingWorkspace"
                              :min="freeCouponDevicesNumber || 0"
                              style="margin-bottom: 8px; width: 70px"
                              size="small"
                            />
                            <a-tooltip title="Save">
                              <a-button
                                type="primary"
                                size="small"
                                html-type="submit"
                                :disabled="updatingWorkspace"
                              >
                                <template #icon>
                                  <SaveOutlined />
                                </template>
                              </a-button>
                            </a-tooltip>
                          </a-input-group>
                        </a-form>
                      </span>
                    </span>
                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col
              class="gutter-row"
              :span="5"
            >
              <a-card class="gutter-box">
                <a-statistic :value="freeCouponDevicesNumber || 'None'">
                  <template #title>
                    FREE Coupon Devices
                  </template>
                  <template
                    v-if="freeDevicesCoupon"
                    #suffix
                  >
                    <a-typography-text type="secondary">
                      Expires {{ deactivationDateToday ? 'Today' : deactivationDate }}
                    </a-typography-text>
                  </template>
                </a-statistic>
              </a-card>
            </a-col>
          </a-row>
          <a-card
            style="width: 100%; flex: 1; height: 100%; display: flex; flex-direction: column;"
            class="full-height-card"
            :tab-list="tabList"
            :active-tab-key="activeTabKey"
            @tab-change="key => onTabChange(key, 'noTitleKey')"
          >
            <div style="height: 100%;">
              <template v-if="activeTabKey === 'workspace'">
                <WorkspaceTab
                  :workspace="workspace"
                  :members="members"
                  :workspace-id="workspaceId"
                  :group-id="groupId"
                  @update-members="onMembersUpdate"
                />
              </template>
              <template v-else-if="activeTabKey === 'subscription'">
                <SubscriptionTab
                  :workspace-id="workspaceId"
                  :workspace="workspace"
                  @update="onSubscriptionUpdate"
                />
              </template>
              <template v-else-if="activeTabKey === 'members'">
                <MembersTab
                  :workspace-id="workspaceId"
                  :workspace="workspace"
                  :members="members"
                  @update="onSubscriptionUpdate"
                />
              </template>
              <template v-else-if="activeTabKey === 'devices'">
                <DevicesTab
                  :workspace-id="workspaceId"
                />
              </template>
            </div>
          </a-card>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<style scoped lang="less">
.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}
.full-height-card {
  height: 100%;
  overflow: hidden;
  :deep(.ant-card-body) {
    height: 100%;
    padding: 24px 24px 24px 8px;
    overflow-x: auto;
  }
}

.gutter-row {
  height: 100%;
  .ant-card {
    height: 100%;
  }
}
</style>

<script setup>
import { CopyOutlined, CheckOutlined, LinkOutlined, MailOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { copyToClipboard, error, formatDate, success } from '@/utils'
import { useMutation } from '@vue/apollo-composable'
import {
  APPLY_RIGHT_TO_BE_FORGOTTEN,
  CHANGE_USER_EMAIL,
  GENERATE_USER_PASSWORD_RESET_LINK,
  VERIFY_USER_EMAIL
} from '@/graphql/mutations'
import { COMPANY_SIZE_LIST, LANGUAGES_LIST } from '@/constants'
import { computed, nextTick, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  owner: {
    type: Object,
    required: true
  },
  isOwner: {
    type: Boolean,
    required: true
  }
})

const changeEmailRules = {
  email: [{
    required: true,
    message: 'New email is required',
    trigger: 'blur'
  },
  {
    type: 'email',
    message: 'Enter valid email address',
    trigger: 'blur'
  }]
}

const CREATED_BY_MAP = {
  V1_MIGRATION: 'Migrated',
  V2_GOOGLE_SSO_LOGIN: 'Google SSO',
  V2_INTERNAL: 'Internal',
  V2_MICROSOFT_SSO_LOGIN: 'Microsoft SSO',
  V2_OIDC_SSO_LOGIN: 'OIDC SSO',
  V2_REGISTRATION: 'Registration'
}

const INDUSTRY_MAP = {
  "HEALTHCARE": "Healthcare",
  "RETAIL": "Retail",
  "FINANCE": "Finance",
  "HOSPITALITY": "Hospitality",
  "TECHNOLOGY": "Technology",
  "REAL_ESTATE": "Real Estate",
  "EDUCATION": "Education",
  "MANUFACTURING": "Manufacturing",
  "TRANSPORTATION_LOGISTICS": "Transportation & Logistics",
  "MEDIA_ENTERTAINMENT": "Media & Entertainment",
  "NON_PROFIT": "Non-Profit",
  "GOVERNMENT": "Government",
  "OTHER": "Other"
}

const emit = defineEmits(['update'])

const router = useRouter()

const { mutate: verifyUserEmail } = useMutation(VERIFY_USER_EMAIL)
const { mutate: changeUserEmail } = useMutation(CHANGE_USER_EMAIL)
const { mutate: generateResetLink, loading: generatingLink } = useMutation(GENERATE_USER_PASSWORD_RESET_LINK)
const { mutate: applyRightToBeForgotten, loading: deletingUser } = useMutation(APPLY_RIGHT_TO_BE_FORGOTTEN)

const userCreatedBy = computed(() => CREATED_BY_MAP[props.user?.userCreatedBy])

const showChangeUserEmailModal = ref(false)
const emailChangeInProcess = ref(false)
const changeEmailInputRef = ref(null)
const changeEmailFormRef = ref(null)
const changeEmailFormState = reactive({
  email: ''
})

const startUserEmailChange = () => {
  showChangeUserEmailModal.value = true
  nextTick(() => {
    changeEmailInputRef.value && changeEmailInputRef.value.focus()
  })
}

const verifyEmail = (userId) => {
  verifyUserEmail({ userId }).then(() => {
    success('Email verified')
    emit('update')
  })
}

const cancelUserEmailChange = () => {
  changeEmailFormState.email = ''
  emailChangeInProcess.value = false
  changeEmailInputRef.value && changeEmailInputRef.value.blur()
  changeEmailFormRef.value && changeEmailFormRef.value.resetFields()
  showChangeUserEmailModal.value = false
}

const onChangeEmailProceed = async () => {
  if (emailChangeInProcess.value) return
  try {
    await changeEmailFormRef.value.validate()
  } catch (e) {
    if (e.errorFields.length) {
      return
    }
  }
  emailChangeInProcess.value = true
  changeUserEmail({ userId: props.user.Id, input: { email: changeEmailFormState.email } })
      .then(() => {
        cancelUserEmailChange()
        success('Email changed')
        emit('update')
      })
      .catch(e => {
        error(e.message)
      }).then(() => {
    emailChangeInProcess.value = false
  })
}

const getResetPasswordLink = () => {
  generateResetLink({ userId: props.user.id }).then(({ data: { generateUserPasswordResetLink: { url } } }) => {
    copyToClipboard(url)
  }).catch((e) => {
    error(e.message)
  })
}

const handleRemoveUser = () => {
  applyRightToBeForgotten({ userId: props.user.id }).then(() => {
    success('Done')
    router.push({ name: 'users', replace: true })
  }).catch((e) => {
    error(e.message)
  })
}

const goToOwner = () => {
  router.push({ name: 'user', params: { userId: props.owner.user.id } })
}

</script>

<template>
  <a-space
    direction="vertical"
    style="width: 100%;"
  >
    <a-modal
      v-model:open="showChangeUserEmailModal"
      width="400px"
      title="Change User Email Address"
      centered
      :closable="false"
      @cancel="cancelUserEmailChange"
      @ok="onChangeEmailProceed"
    >
      <a-form
        ref="changeEmailFormRef"
        :model="changeEmailFormState"
        :rules="changeEmailRules"
        @submit.prevent="onChangeEmailProceed"
      >
        <a-form-item name="email">
          <a-input
            ref="changeEmailInputRef"
            v-model:value="changeEmailFormState.email"
            placeholder="New Email"
            name="email"
          />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="emailChangeInProcess"
          @click="onChangeEmailProceed"
        >
          Change Email Address
        </a-button>
      </template>
    </a-modal>
    <a-descriptions
      :title="user.firstName + ' ' + user.lastName"
      layout="horizontal"
      size="small"
      bordered
    >
      <template #extra>
        <a-space>
          <a-popconfirm
            v-if="!user.emailVerified"
            title="Are you sure?"
            @confirm="verifyEmail(user.id)"
          >
            <a-tooltip title="Verify Email">
              <CheckOutlined />
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip title="Copy id">
            <CopyOutlined @click="copyToClipboard(user.id)" />
          </a-tooltip>
        </a-space>
      </template>
      <a-descriptions-item
        label="Email"
        :span="3"
      >
        <div @click="copyToClipboard(user.email)">
          <a-tooltip :title="user.emailVerified ? 'Verified' : 'Unverified'">
            <a-badge
              :status="user.emailVerified ? 'success' : 'error'"
              :text="user.email"
            />
          </a-tooltip>
        </div>
      </a-descriptions-item>
      <a-descriptions-item
        label="Phone"
        :span="3"
      >
        <div @click="copyToClipboard(user.phone)">
          {{ user.phone || 'Unset' }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item
        label="Company"
        :span="3"
      >
        <div @click="copyToClipboard(user.companyName)">
          {{ user.companyName || 'Unset' }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item
        label="Industry"
        :span="3"
      >
        {{ user.companyIndustry ? INDUSTRY_MAP[user.companyIndustry] : 'Unset' }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="!isOwner"
        label="Owner"
        :span="3"
      >
        <a-typography-link @click="goToOwner()">
          {{ owner?.user?.firstName }} {{ owner?.user?.lastName }}
        </a-typography-link>
      </a-descriptions-item>
      <a-descriptions-item
        label="Size"
        :span="3"
      >
        {{ user.companySize ? COMPANY_SIZE_LIST[user.companySize] : 'Unset' }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Password"
        :span="3"
      >
        <a-badge
          :status="user.isPasswordSet ? 'success' : 'error'"
          :text="user.isPasswordSet ? 'Set' : 'Not Set'"
        />
      </a-descriptions-item>
      <a-descriptions-item
        label="Created By"
        :span="3"
      >
        {{ userCreatedBy }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Created"
        :span="3"
      >
        {{ formatDate(user.createdAt) }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Upadated"
        :span="3"
      >
        {{ formatDate(user.updatedAt) }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="user.deletedAt"
        label="Deleted"
        :span="3"
      >
        {{ formatDate(user.deletedAt) }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Language"
        :span="3"
      >
        {{ user.language ? LANGUAGES_LIST[user.language] : 'Unset' }}
      </a-descriptions-item>
    </a-descriptions>
    <a-button
      type="primary"
      block
      @click="startUserEmailChange"
    >
      <template #icon>
        <MailOutlined />
      </template>
      Change user email
    </a-button>
    <a-button
      type="primary"
      block
      :loading="generatingLink"
      @click="getResetPasswordLink"
    >
      <template #icon>
        <LinkOutlined />
      </template>
      Reset pwd link
    </a-button>
    <a-popconfirm
      title="Are you sure?"
      @confirm="handleRemoveUser"
    >
      <a-button
        danger
        block
        :loading="deletingUser"
      >
        <template #icon>
          <DeleteOutlined />
        </template>
        Delete user
      </a-button>
    </a-popconfirm>
  </a-space>
</template>

<style scoped lang="less">
:deep(.ant-descriptions) {
  .ant-descriptions-row {
    white-space: nowrap;
  }
  overflow: hidden;
  .ant-badge.ant-badge-status {
    white-space: nowrap;
  }
}
</style>

<template>
  <a-layout
    v-if="user"
    style="min-height: 100%;"
  >
    <a-layout-content style="padding: 24px; margin: auto;">
      <a-typography-title
        :level="2"
        type="secondary"
        align="center"
      >
        General stats
      </a-typography-title>
      <a-row
        style="margin-top: 24px;"
        :gutter="8"
      >
        <a-col :span="12">
          <a-row :gutter="[8,8]">
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Ongoing This Week"
                  :value="stats?.stripe?.currentWeekUpcomingInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Ongoing This Month"
                  :value="stats?.stripe?.currentMonthUpcomingInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Ongoing This Year"
                  :value="stats?.stripe?.currentYearUpcomingInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Revenue This Week"
                  :value="stats?.stripe?.currentWeekPaidInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Revenue This Month"
                  :value="stats?.stripe?.currentMonthPaidInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Revenue This Year"
                  :value="stats?.stripe?.currentYearPaidInvoicesAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="New Payments This Week"
                  :value="stats?.stripe?.currentWeekNewSubscriptionsAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="New Payments This Month"
                  :value="stats?.stripe?.currentMonthNewSubscriptionsAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="New Payments This Year"
                  :value="stats?.stripe?.currentYearNewSubscriptionsAmount"
                  prefix="$"
                  :formatter="formatCurrency"
                />
              </a-card>
            </a-col>
            <a-col
              :span="8"
              :offset="8"
            >
              <a-card>
                <a-statistic
                  title="Devices Total"
                  :value="stats?.devices?.totalCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Devices Online"
                  :value="stats?.devices?.onlineDevicesCount"
                />
              </a-card>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12">
          <a-row :gutter="[8,8]">
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="All Users"
                  :value="stats?.users?.totalCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Active Users"
                  :value="stats?.workspaces?.paidCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Inactive Users"
                  :value="stats?.workspaces?.inactiveCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Workspace Owners"
                  :value="stats?.workspaces?.totalCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Past Due Users"
                  :value="stats?.workspaces?.pastDueCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Demo Users"
                  :value="stats?.workspaces?.demoCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Enterprize Users Owners"
                  :value="stats?.enterprizeWorkspaces?.totalCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Enterprize Users Past Due"
                  :value="stats?.enterprizeWorkspaces?.pastDueCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Growth Users Owners"
                  :value="stats?.growthWorkspaces?.totalCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Growth Users Past Due"
                  :value="stats?.growthWorkspaces?.pastDueCount"
                />
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card>
                <a-statistic
                  title="Migrated Users"
                  :value="stats?.users?.usersCreatedByV1MigrationCount"
                />
              </a-card>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useQuery, useResult } from '@vue/apollo-composable'
import { GET_STATS } from '@/graphql/queries'

export default defineComponent({
  name: 'GeneralPage',
  setup () {
    const store = useStore()
    const { result: statsResult } = useQuery(GET_STATS)
    const stats = useResult(statsResult)

    const user = computed(() => store.getters['auth/user'])

    const formatCurrency = ({ value }) => {
      return value ? value / 100 : '0.00'
    }

    return {
      user,
      stats,
      formatCurrency
    }
  }
})
</script>

<style scoped>

</style>

<template>
  <a-layout style="min-height: 100%;">
    <a-layout-content style="padding: 60px 16px; height: 100%;">
      <a-typography-title
        :level="2"
        style="text-align: center"
      >
        Vision
      </a-typography-title>
      <div v-if="!show2Fa">
        <a-form
          ref="loginForm"
          style="margin: auto; width: 400px"
          :model="loginFromState"
          autocomplete="off"
          layout="vertical"
          :rules="rules"
          @finish="login"
        >
          <a-form-item
            label="Email"
            name="username"
          >
            <a-input
              v-model:value="loginFromState.username"
              placeholder="Email"
            />
          </a-form-item>
          <a-form-item
            label="Password"
            name="password"
          >
            <a-input
              v-model:value="loginFromState.password"
              placeholder="Password"
              type="password"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="loading"
            >
              Submit
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div v-else>
        <a-form
          ref="2FaFormRef"
          layout="vertical"
          style="margin: auto; width: 400px"
          @submit.prevent="loginWith2Fa"
        >
          <a-form-item
            label="Two factor code"
            name="code"
          >
            <a-input
              v-model:value="code2Fa"
              placeholder="Code"
              maxlength="6"
              type="text"
            />
          </a-form-item>
          <a-space>
            <a-button
              type="primary"
              html-type="submit"
              :loading="passingTwoFactorCheck"
            >
              Login
            </a-button>
            <a-button
              :disabled="passingTwoFactorCheck"
              @click="handleCancel2Fa"
            >
              Cancel
            </a-button>
          </a-space>
        </a-form>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { error } from '@/utils'

export default defineComponent({
  name: 'LoginPage',
  setup () {
    const store = useStore()
    const router = useRouter()
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const show2Fa = ref(false)
    const code2Fa = ref('')
    const preAuthToken = ref('')
    const passingTwoFactorCheck = ref(false)

    const loginFromState = reactive({
      username: '',
      password: ''
    })

    onMounted(() => {
      if (isAuthenticated.value) {
        return router.push({ name: 'general' })
      }
    })

    const loginForm = ref()

    const rules = {
      username: {
        required: true,
        type: 'email',
        trigger: 'change',
        message: 'Please enter valid email'
      },
      password: {
        required: true,
        trigger: 'change',
        message: 'Please enter password'
      }
    }

    const loading = ref(false)

    const login = async () => {
      const input = toRaw(loginFromState)
      loading.value = true
      try {
        await store.dispatch('auth/login', input).then((extensions) => {
          if (extensions?.requires2FA) {
            preAuthToken.value = extensions?.preAuthToken
            show2Fa.value = true
            return
          }
          router.push({ name: 'admin' })
        })
      } catch (e) {
        console.log(e)
      }
      loading.value = false
    }

    const reset2FaState = () => {
      show2Fa.value = false
      code2Fa.value = ''
      preAuthToken.value = ''
    }

    const loginWith2Fa = async () => {
      if (code2Fa.value?.length < 6) return
      passingTwoFactorCheck.value = true
      await store.dispatch('auth/loginWith2Fa', { preAuthToken: preAuthToken.value, twoFactorCode: code2Fa.value })
        .then(() => {
          router.push({ name: 'admin' })
        }).catch((e) => {
          console.log(e)
          error('Code is incorrect')
        }).finally(() => {
          passingTwoFactorCheck.value = false
        })
    }

    const handleCancel2Fa = () => {
      reset2FaState()
    }

    return {
      loginFromState,
      loading,
      rules,
      loginForm,
      code2Fa,
      show2Fa,
      passingTwoFactorCheck,
      handleCancel2Fa,
      loginWith2Fa,
      login
    }
  }
})
</script>

<style scoped>

</style>

<script setup>
import { defineProps, ref, watchEffect, defineEmits } from 'vue'
import { cloneDeep } from 'lodash'
import { error, success } from '@/utils'
import { useMutation } from '@vue/apollo-composable'
import { UPDATE_WORKSPACE_BY_ID } from '@/graphql/mutations'
const props = defineProps({
  features: {
    type: Object,
    required: true
  },
  workspaceId: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['update'])

const featuresState = ref({})
const { mutate: updateWorkspace, updating } = useMutation(UPDATE_WORKSPACE_BY_ID)

const toggleFeature = (feature, value) => {
  updateWorkspace({
    id: props.workspaceId,
    input: {
      features: {
        [feature]: value
      }
    }
  }).then(() => {
    emit('update')
    success()
  }).catch(e => {
    error(e.message)
  })
}

watchEffect(() => {
  if (props.features) {
    const features = cloneDeep(props.features)
    delete features.__typename
    featuresState.value = features
  }
})
</script>

<template>
  <a-descriptions layout="horizontal" bordered style="text-align: left; overflow-x: auto">
    <a-descriptions-item
      :label="feature.replace('Enabled', '')"
      v-for="(value, feature) in featuresState"
      :key="feature"
      :span="3"
    >
      <a-switch :disabled="updating" :checked="value" checked-children="Yes" un-checked-children="No" @click="(value)=>toggleFeature(feature, value)"/>
    </a-descriptions-item>
  </a-descriptions>
</template>

<style scoped>

</style>

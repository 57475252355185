<script setup>
import GroupsSidebar from '@/components/GroupsSidebar.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()
const route = useRoute()

const selectedMenuKeys = computed(() => {
  return [route.name]
})

defineProps({
  workspace: {
    type: Object,
    required: true
  },
  workspaceId: {
    type: String,
    required: true
  },
  groupId: {
    type: String,
    required: true
  }
})

const onGroupChange = (groupId) => {
  const destinationParams = { params: { groupId }, replace: true }
  if (route.name === 'workspace') {
    destinationParams.name = 'devices'
  }
  groupId && router.push(destinationParams)
}

</script>

<template>
  <a-layout style="height: 100%; overflow: hidden; background-color: transparent">
    <GroupsSidebar
      v-if="workspaceId"
      :key="workspaceId"
      :group="groupId"
      :workspace-id="workspaceId"
      @group-change="onGroupChange"
    />
    <a-layout-content style="padding-left: 24px; display: flex;">
      <div style="display: flex; flex-direction: column; gap: 8px; width: 100%;">
        <a-menu
          v-if="groupId"
          mode="horizontal"
          :selected-keys="selectedMenuKeys"
        >
          <a-menu-item key="devices">
            <router-link :to="{name:'devices'}">
              Devices
            </router-link>
          </a-menu-item>
          <a-menu-item key="playlists">
            <router-link :to="{name:'playlists'}">
              Playlists
            </router-link>
          </a-menu-item>
        </a-menu>
        <router-view />
      </div>
    </a-layout-content>
  </a-layout>
</template>

<style scoped>

</style>

<template>
  <a-modal
    v-model:open="showDeviceModal"
    :title="selectedDevice?.name"
    :footer="null"
    width="1000px"
  >
    <JsonViewer
      :value="selectedDevice"
      copyable
      expanded
      sort
      theme="jv-light"
    />
  </a-modal>
  <a-table
    :data-source="devicesList"
    :columns="columns"
    :loading="loading"
    size="small"
    :pagination="paginationConfig"
    :custom-row="customRow"
    style="margin-top: 8px; overflow-x: auto"
    :scroll="{ x: 800 }"
    class="table-padded"
    @resize-column="handleResizeColumn"
    @change="onChange"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="['createdAt','updatedAt','lastActivityAt'].includes(column.key)">
        {{ formatDate(text) }}
      </template>
      <template v-else-if="column.key === 'hardwareInfo'">
        {{ text?.tvos }}
      </template>
      <template v-else-if="column.key === 'mdm'">
        <a-badge
          :status="record.externalId ? 'success' : 'error'"
          :text="record.externalId ? 'Yes' : 'No'"
        />
      </template>
      <template v-else>
        {{ text }}
      </template>
    </template>
  </a-table>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref, toRef } from 'vue'
import { LIST_DEVICES } from '@/graphql/queries'
import { useQuery } from '@vue/apollo-composable'
import { DEFAULT_PAGINATION } from '@/constants'
import { cloneDeep } from 'lodash'
import { formatDate } from '@/utils'

const COLUMNS = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  ellipsis: true,
  width: 200,
  minWidth: 50,
  maxWidth: 400,
  resizable: true,
  fixed: 'left'
}, {
  title: 'tvOS',
  key: 'hardwareInfo',
  dataIndex: 'hardwareInfo',
  width: 70,
  ellipsis: true
}, {
  title: 'App Version',
  dataIndex: 'appVersion',
  key: 'appVersion',
  width: 100,
  ellipsis: true
}, {
  title: 'MDM',
  dataIndex: 'mdm',
  width: 60,
  key: 'mdm'
}, {
  title: 'Timezone',
  dataIndex: 'timezone',
  width: 100
}, {
  title: 'Orientation',
  dataIndex: 'orientation',
  key: 'orientation',
  width: 100,
  minWidth: 100
}, {
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  align: 'createdAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Updated At',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  align: 'updatedAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Last activity At',
  dataIndex: 'lastActivityAt',
  key: 'lastActivityAt',
  align: 'lastActivityAt',
  width: 150,
  minWidth: 150
}]

export default defineComponent({
  name: 'DevicesView',
  props: {
    groupId: {
      type: String
    },
    userId: {
      type: String
    },
    workspaceId: {
      type: String
    }
  },
  setup (props) {
    const groupId = toRef(props, 'groupId')
    const enabled = ref(!!groupId.value)
    const settings = ref({ enabled })
    const selectedDevice = ref(null)
    const showDeviceModal = ref(false)
    const queryOptions = ref({ filters: { groupId }, pagination: { ...DEFAULT_PAGINATION } })
    const { onResult, loading } = useQuery(LIST_DEVICES, queryOptions, settings)
    const devicesList = ref([])
    const paginationResult = ref({})
    const paginationConfig = computed(() => {
      return {
        total: paginationResult.value?.total,
        pageSize: DEFAULT_PAGINATION.limit
      }
    })
    const columns = ref(cloneDeep(COLUMNS))

    onResult(({ data }) => {
      if (data?.listDevices?.data) {
        devicesList.value = data.listDevices.data
        paginationResult.value = data.listDevices.pagination
      }
    })

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const onChange = async (pagination) => {
      queryOptions.value.pagination.offset = (pagination.current - 1) * DEFAULT_PAGINATION.limit + 1
    }

    const customRow = (record) => {
      return {
        onClick: () => {
          selectedDevice.value = record
          showDeviceModal.value = true
        }
      }
    }

    onMounted(() => {
      settings.value.enabled = false
      nextTick(() => {
        settings.value.enabled = true
      })
    })

    return {
      columns,
      loading,
      devicesList,
      selectedDevice,
      paginationConfig,
      showDeviceModal,
      customRow,
      formatDate,
      onChange,
      handleResizeColumn
    }
  }
})
</script>

<style scoped>

</style>

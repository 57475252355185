<template>
  <a-layout
    v-if="user"
    style="min-height: 100%;"
  >
    <a-layout-content
      style="padding: 24px; margin: auto; width: 100%;"
    >
      <a-typography-title
        :level="2"
        type="secondary"
        align="center"
      >
        Third Party Services
      </a-typography-title>
      <a-spin :spinning="statusesLoading || qtysLoading">
        <a-row
          style="margin-top: 24px;"
          :gutter="8"
        >
          <a-col
            v-if="thirdPartyStatuses"
            :span="12"
          >
            <a-descriptions
              bordered
              size="small"
            >
              <a-descriptions-item
                v-for="apiStatus in apiStatuses"
                :key="apiStatus.name"
                :span="2"
                :label="apiStatus.name"
              >
                <div
                  style="text-align: center"
                >
                  <a-badge
                    :status="getStatusStyle(thirdPartyStatuses?.[apiStatus.key])"
                  >
                    <template #text>
                      <span :style="{color: thirdPartyStatuses?.[apiStatus.key] ? 'green' : 'red'}">
                        {{ getStatusText(thirdPartyStatuses?.[apiStatus.key]) }}
                      </span>
                    </template>
                  </a-badge>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col
            v-if="trackingQuantities"
            :span="12"
          >
            <a-descriptions
              size="small"
              bordered
            >
              <a-descriptions-item
                v-for="item in trackingQuantities"
                :key="item.name"
                :label="item.name?.replace(/-/gi, ' ').replace('count', '')"
                :span="2"
              >
                <div
                  style="text-align: center"
                  :style="{color: getQuantityStatus(item) ? 'green' : 'red'}"
                >
                  {{ `${item.value} / ${item.maxValue || '∞'}` }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useQuery, useResult } from '@vue/apollo-composable'
import { GET_THIRD_PARTY_STATUSES, GET_TRACKING_QUANTITIES } from '@/graphql/queries'

export default defineComponent({
  name: 'GeneralPage',
  setup () {
    const store = useStore()
    const { result: thirdPartyStatusesResult, loading: statusesLoading } = useQuery(GET_THIRD_PARTY_STATUSES)
    const { result: trackingQuantitiesResult, loading: qtysLoading } = useQuery(GET_TRACKING_QUANTITIES)
    const thirdPartyStatuses = useResult(thirdPartyStatusesResult)
    const trackingQuantities = useResult(trackingQuantitiesResult)

    const user = computed(() => store.getters['auth/user'])

    const formatCurrency = ({ value }) => {
      return value ? value / 100 : '0.00'
    }

    const getStatusText = (status) => {
      return status ? 'OK' : 'NOT OK'
    }

    const getStatusStyle = (status) => {
      return status ? 'success' : 'error'
    }

    const apiStatuses = [
      { name: 'Dropbox Auth', key: 'dropboxOauthApiOk'},
      { name: 'Google Auth', key: 'googleOauthApiOk'},
      { name: 'Instagram Auth', key: 'instagramOauthApiOk'},
      { name: 'Microsoft Auth', key: 'microsoftOauthApiOk'},
      { name: 'Twitter Auth', key: 'twitterOauthApiOk'},
      { name: 'Weather Api', key: 'weatherApiOk'},
      { name: 'Yelp Api', key: 'yelpApiOk'},
      { name: 'Covid Api', key: 'covidStatsApiOk'},
      { name: 'Google Reviews API', key: 'googleReviewsApiOk'},
      { name: 'Fun Facts API', key: 'rapidApiFunFactsApiOk'},
      { name: 'Horoscopes Api', key: 'rapidApiHoroscopesApiOk'},
      { name: 'Holidays API', key: 'rapidApiPublicHolidaysApiOk'},
      { name: 'Tasty API', key: 'rapidApiTastyApiOk'},
      { name: 'Ted Talks Api', key: 'rapidApiTedTalksApiOk'},
      { name: 'Ticker API', key: 'tickerApiOk'},
      { name: 'Trip Advisor API', key: 'tripAdvisorApiOk'},
      { name: 'News API', key: 'newsApiOk'}
    ]

    const getQuantityStatus = ({ value, maxValue, currentPeriodStart, currentPeriodEnd }) => {
      if (maxValue === null) return true
      return value / maxValue < 0.1 || (new Date() - currentPeriodStart) / (currentPeriodEnd - currentPeriodStart) > value / maxValue
    }

    return {
      user,
      thirdPartyStatuses,
      trackingQuantities,
      apiStatuses,
      statusesLoading,
      qtysLoading,
      getStatusText,
      getStatusStyle,
      getQuantityStatus,
      formatCurrency
    }
  }
})
</script>

<style scoped>

</style>

<script setup>
import { DownOutlined, LoginOutlined, StarFilled, AlertOutlined, CopyOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { useMutation } from '@vue/apollo-composable'
import { LOGIN_AS_USER, UPDATE_WORKSPACE_BY_ID } from '@/graphql/mutations'
import { computed } from 'vue'
import { copyToClipboard, error, success } from '@/utils'
const router = useRouter()
const props = defineProps({
  workspaces: Array,
  workspace: Object,
  workspaceId: String,
  workspaceRole: String,
  userId: String,
})

const emit = defineEmits(['openFeaturesDrawer', 'updateWorkspace'])

const { mutate: loginAsUser } = useMutation(LOGIN_AS_USER)
const { mutate: updateWorkspace, updatingWorkspace } = useMutation(UPDATE_WORKSPACE_BY_ID)


const isEnterpise = computed(() => props.workspace?.billingInfo?.enterprize)
const isFrozen = computed(() => props.workspace?.frozen)
const frozenReason = computed(() => props.workspace?.frozenReason)


const changeWorkspace = (id) => {
  router.push({params: {workspaceId: id}})
}

const handleLoginAsUser = () => {
  loginAsUser({ userId: props.userId, workspaceId: props.workspaceId }).then(({ data: { loginOnBehalfOfUser } }) => {
    const { accessToken } = loginOnBehalfOfUser
    window.open(`${process.env.VUE_APP_DASHBOARD_URL}/login-as/${accessToken}`, '_blank')
  })
}


const toggleUserFreeze = () => {
  updateWorkspace({
    id: props.workspaceId,
    input: {
      frozen: !isFrozen.value
    }
  }).then(() => {
    emit('updateWorkspace')
    success()
  }).catch(e => {
    error(e.message)
  })
}

</script>

<template>
  <a-card
    style="width: 100%"
  >
    <template #title>
      <a-dropdown
        v-if="workspaces?.length > 1"
        trigger="['click']"
      >
        <a
          class="ant-dropdown-link"
          @click.prevent
        >
          {{ workspace?.name }}
          <DownOutlined style="font-size: 10px;" />
        </a>
        <template #overlay>
          <a-menu>
            <template
              v-for="w in workspaces"
              :key="workspace.id"
            >
              <a-menu-item
                v-if="w.id !==workspace.id"
                @click="changeWorkspace(w.id)"
              >
                <a>{{ w.name }}</a>
              </a-menu-item>
            </template>
          </a-menu>
        </template>
      </a-dropdown>
      <span v-else>{{ workspace?.name }}</span>
      <a-tooltip
        v-if="isEnterpise"
        title="Enterpise"
      >
        <StarFilled style="margin-left: 4px;" />
      </a-tooltip>
      <a-typography-text>
        ({{ workspaceRole }})
      </a-typography-text>
      <a-typography-text
        v-if="isFrozen"
        type="danger"
      >
        ({{ frozenReason }})
      </a-typography-text>
      <a-tooltip title="Copy id">
        <CopyOutlined @click="copyToClipboard(workspaceId)" />
      </a-tooltip>
    </template>
    <template #extra>
      <a-space>
        <a-button
          :loading="updatingWorkspace"
          :type="isFrozen ? 'default' : 'warning'"
          @click="toggleUserFreeze"
        >
          <template #icon>
            <LoginOutlined />
          </template>
          {{ isFrozen ? 'Unfreeze' : 'Freeze' }}
        </a-button>
        <a-button
          type="primary"
          @click="handleLoginAsUser"
        >
          <template #icon>
            <LoginOutlined />
          </template>
          Login as user
        </a-button>
        <a-button
          @click="$emit('openFeaturesDrawer')"
        >
          Features
        </a-button>
      </a-space>
    </template>
  </a-card>
</template>

<style scoped>

</style>

import { ApolloClient, createHttpLink, InMemoryCache, from, defaultDataIdFromObject } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import store from '@/store'
import { setContext } from '@apollo/client/link/context'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      store.dispatch('auth/clearUser')
    }
    if (networkError.statusCode === 400) {
      throw new Error('Something happened')
      // console.log('Something happened')
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (extensions && extensions.code === 'BAD_TOKEN' && !path.includes('logoutUser')) {
        store.dispatch('auth/clearUser')
      }
    })
  }
})

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API_URL
})

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth-token')
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

// Cache implementation
const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'PlaylistModel': return `${object.id}|${object.version}` // to avoid duplicates by id
      default: return defaultDataIdFromObject(object) // fall back to default handling
    }
  }
})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache
})

export default apolloClient
